import React from "react";
import styled, { css } from "styled-components/macro";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Collapse from "@mui/material/Collapse";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SidebarNavSection from "./SidebarNavSection";
import Filter from "./Filter";
import ImageFilter from "./ImageFilter";
import DomainFilter from "./DomainFilter";
import ListingActions from "../../pages/inboxes/Listing/ListingActions";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import MenuIcon from "@mui/icons-material/Menu";

// import "../../vendor/perfect-scrollbar.css";

const baseScrollbar = (props) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PaddedBox = styled(Box)`
  padding: 0px 14px;
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const SidebarNav = ({ items }) => {
  const theme = useTheme();
  const router = useLocation();
  const selected = useSelector((state) => state.app.selectedListings);
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;

  const location = router.pathname;

  const [openFilter, setOpenFilter] = React.useState(true);
  const [openNav, setOpenNav] = React.useState(true);
  const [openActions, setOpenActions] = React.useState(false);

  const handleClickNav = () => {
    setOpenNav(!openNav);
  };

  const handleClickFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleClickActions = () => {
    setOpenActions(!openActions);
  };

  React.useEffect(() => {
    if (selected && selected.length > 0) {
      setOpenActions(true);
      setOpenFilter(false);
    } else {
      setOpenFilter(true);
    }
  }, [selected]);

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <ListItemButton onClick={handleClickNav}>
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
          <ListItemText primary="Navigation" />
          {openNav ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openNav} timeout="auto" unmountOnExit={false}>
          <PaddedBox>
            <Items>
              {items &&
                items.map((item) => (
                  <SidebarNavSection
                    component="div"
                    key={item.title}
                    pages={item.pages}
                    title={item.title}
                  />
                ))}
            </Items>
          </PaddedBox>
        </Collapse>
      </List>

      <Divider mt={6} />
      <React.Fragment>
        <List component="div" disablePadding>
          <ListItemButton onClick={handleClickFilter}>
            <ListItemIcon>
              <FilterListIcon />
            </ListItemIcon>
            <ListItemText primary="Filter" />
            {openFilter ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openFilter} timeout="auto" unmountOnExit={false}>
            <PaddedBox>
              {location === "/images" && <ImageFilter />}
              {location === "/domains" && <DomainFilter />}
              {location !== "/images" && location !== "/domains" && <Filter />}
            </PaddedBox>
          </Collapse>
        </List>
        <Divider mt={6} />
        {location === "/listings" && (
          <List component="div" disablePadding>
            <ListItemButton onClick={handleClickActions}>
              <ListItemIcon>
                <PendingActionsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Actions" />
              {openActions ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openActions} timeout="auto" unmountOnExit>
              <PaddedBox>
                <ListingActions />
              </PaddedBox>
            </Collapse>
          </List>
        )}
      </React.Fragment>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
