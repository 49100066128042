import * as React from "react";

//library
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isEmpty, isEqual } from "lodash";
import { useLocation } from "react-router-dom";

//material
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import TuneIcon from "@mui/icons-material/Tune";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { matchSorter } from "match-sorter";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import styled from "styled-components/macro";
import useAuth from "../../hooks/useAuth";
import CustomDatePicker from "./DatePicker";
import SaveFilterDialog from "./SaveFilterDialog";

//RTK
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllTagsQuery,
  useGetBrandProductsQuery,
  useGetCategoriesQuery,
  useGetCountriesQuery,
  useGetMarketPlacesQuery,
  useGetUserBrandsQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../store/api";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Collapse, List, ListItemButton } from "@mui/material";
import { setDateRange, setParams } from "../../store/app";
import { hasRole } from "../../utils/auth";
import {
  COMPLAINT_STATUS_TYPES,
  INFRINGEMENT_TYPES,
  LISTING_STATUS,
  PLATFORM_TYPE,
  STATE_TYPES,
  TESTPURCHASE_STATUS,
} from "../../utils/labels";
import CountrySelect from "../controls/CountrySelect";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
      left: "0px !important",
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.custom.primary};
`;

const Filter = () => {
  const { user, setUser } = useAuth();
  const router = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useSelector((state) => state.app.params);
  const location = router.pathname;
  const [updateUser] = useUpdateUserMutation();
  const [saveFilter, setSaveFilter] = React.useState(false);
  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue);
  const [clients, setClients] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [assigned, setAssigned] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [channels, setChannels] = React.useState([]);
  const [startDate, setStartDate] = React.useState(dayjs(params.start));
  const [endDate, setEndDate] = React.useState(dayjs(params.end));
  const [tags, setTags] = React.useState([]);
  const [listingStatus, setListingStatus] = React.useState([]);
  const [purchaseStatus, setPurchaseStatus] = React.useState([]);
  const [marketplaces, setMarketplaces] = React.useState([]);
  const [infringements, setInfringements] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [seller, setSeller] = React.useState("");
  const [countryOptions, setCountryOptions] = React.useState([]);
  const [marketPlaceOptions, setMarketPlaceOptions] = React.useState([]);
  const [testPurchase, setTestPurchase] = React.useState(false);
  const [dateField, setDateField] = React.useState("Created");
  const [value, setValue] = React.useState("");
  const [sortBy, setSortBy] = React.useState(params.sortBy);
  const [order, setOrder] = React.useState(params.order);
  const [openAdvancedFilter, setOpenAdvancedFilter] = React.useState(false);
  const [allClients, setAllClients] = React.useState([]);
  const [allBrands, setAllBrands] = React.useState([]);
  const [brandOpen, setBrandOpen] = React.useState(false);
  const [assignedOpen, setAssignedOpen] = React.useState(false);
  const [filed, setFiled] = React.useState("all");
  const [categories, setCategories] = React.useState([]);

  const { data: users } = useGetUsersQuery(user ? undefined : skipToken);

  const handleFiled = (_event, filed) => {
    setFiled(filed);
  };

  const handleClickAdvancedFilter = () => {
    setOpenAdvancedFilter((prev) => !prev);
  };
  const { data: all_countries } = useGetCountriesQuery(
    user?.auth ? undefined : skipToken
  );
  const { data: all_categories } = useGetCategoriesQuery(
    user?.auth ? undefined : skipToken
  );
  const { data: productData } = useGetBrandProductsQuery(
    brands.length > 0 ? { brands: brands } : skipToken
  );

  const handleDeleteFilter = (index) => {
    updateUser({
      ...user,
      ...{ filters: user.filters.filter((_f, i) => index !== i) },
    }).then((resp) => {
      setUser(resp.data);
    });
  };

  const handleSaveFilter = () => {
    setSaveFilter(true);
  };

  React.useEffect(() => {
    if (params.countries.length || params.value) {
      setOpenAdvancedFilter(true);
    }
  }, [params.countries, params.value]);

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(date);
      dispatch(
        setDateRange({
          start: date.valueOf(),
          end: params.end,
        })
      );
    } else {
      setEndDate(date);
      dispatch(
        setDateRange({
          start: params.start,
          end: date.valueOf(),
        })
      );
    }
  };

  React.useEffect(() => {
    if (all_countries !== undefined && !isEmpty(all_countries)) {
      let tempCountryOptions = [];
      Object.keys(all_countries)
        .sort()
        .forEach((key) => {
          tempCountryOptions.push({ text: all_countries[key], value: key });
        });
      setCountryOptions(tempCountryOptions);
    }
  }, [all_countries]);

  const { data: all_marketplaces } = useGetMarketPlacesQuery(
    user?.auth ? undefined : skipToken
  );
  const { data: all_brands } = useGetUserBrandsQuery(
    user?.auth ? undefined : skipToken
  );
  const { data: all_tags } = useGetAllTagsQuery(
    user?.auth ? undefined : skipToken
  );

  const initClients = () => {
    if (all_brands?.length > 0 && user?.brands?.length > 0) {
      const filteredClients = all_brands.filter((brand) => {
        return user.brands.includes(brand.name);
      });
      const clientNames = filteredClients.map((obj) => obj?.client).sort();
      const uniqueClients = [...new Set(clientNames)];
      setAllClients(uniqueClients);
      setClients([uniqueClients[0]]);
    }
  };

  React.useEffect(() => {
    initClients();
    // eslint-disable-next-line
  }, [all_brands, user]);

  React.useEffect(() => {
    if (clients?.length && all_brands?.length) {
      const filteredBrands = all_brands.filter((brand) =>
        clients.includes(brand.client)
      );
      const brandNames = filteredBrands.map((brand) => brand.name).sort();
      const uniqueBrands = [...new Set(brandNames)];
      setAllBrands(uniqueBrands);
      setBrands(uniqueBrands);
    } else {
      setAllBrands(user?.brands);
    }
    // eslint-disable-next-line
  }, [clients, user, all_brands]);

  const refineMarketplaceData = (marketplacesData) => {
    if (marketplacesData !== undefined && !isEmpty(marketplacesData)) {
      let tempMarketPlaceOptions = [];
      Object.keys(marketplacesData)
        .sort()
        .forEach((key) => {
          if (marketplacesData[key]) {
            let channel = marketplacesData[key]["channel"];
            if (channel === "MARKETPLACE" || channel === "SOCIAL_MEDIA") {
              tempMarketPlaceOptions.push({
                text: marketplacesData[key]["name"],
                value: key,
                channel: channel,
              });
            }
          }
        });
      setMarketPlaceOptions(tempMarketPlaceOptions);
    }
  };

  const findGroup = (groups) => {
    if (groups.includes("client")) {
      return "Clients";
    } else if (groups.includes("analyst")) {
      return "Analysts";
    }
  };

  const brandMatch = (u) => {
    return u.brands?.filter((value) => user.brands?.includes(value)).length > 0;
  };

  const getUserOptions = () => {
    let options = [];
    users
      .filter((u) => {
        return (
          brandMatch(u) &&
          (u.groups?.includes("client") || u.groups?.includes("analyst"))
        );
      })
      .forEach((user) => {
        options.push({
          label: user.name,
          value: user.providerId,
          group: findGroup(user.groups),
        });
      });
    return options.sort((a, b) => a.group.localeCompare(b.group));
  };

  React.useEffect(() => {
    refineMarketplaceData(all_marketplaces);
  }, [all_marketplaces]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sellerId = urlParams.get("sellerId")?.toString();
    const marketplace = urlParams.get("marketplace");
    const value = urlParams.get("value");
    if (sellerId || marketplace || value) {
      updateFilters("clear");
      setSeller(sellerId);
      setMarketplaces([marketplace]);
      setValue(value);
    }
    // eslint-disable-next-line
  }, []);

  const updateFilters = (type) => {
    if (type === "brands" && isEqual(brands, params.brands)) {
      return false;
    } else if (type === "clients" && isEqual(clients, params.clients)) {
      return false;
    } else if (type === "products" && isEqual(products, params.products)) {
      return false;
    } else if (
      type === "purchaseStatus" &&
      isEqual(purchaseStatus, params.purchaseStatus)
    ) {
      return false;
    } else if (type === "assigned" && isEqual(assigned, params.assigned)) {
      return false;
    } else if (type === "countries" && isEqual(countries, params.countries)) {
      return false;
    } else if (type === "seller" && isEqual(seller, params.seller)) {
      return false;
    } else if (
      type === "testPurchase" &&
      isEqual(testPurchase, params.testPurchase)
    ) {
      return false;
    } else if (type === "dateField" && isEqual(dateField, params.dateField)) {
      return false;
    } else if (type === "start" && isEqual(startDate.valueOf(), params.start)) {
      return false;
    } else if (type === "end" && isEqual(endDate.valueOf(), params.end)) {
      return false;
    } else if (type === "search" && isEqual(value, params.value)) {
      return false;
    } else if (type === "sortBy" && isEqual(sortBy, params.sortBy)) {
      return false;
    } else if (type === "order" && isEqual(order, params.order)) {
      return false;
    } else if (
      type === "marketplaces" &&
      isEqual(marketplaces, params.marketplaces)
    ) {
      return false;
    } else if (
      type === "infringements" &&
      isEqual(infringements, params.infringementTypes)
    ) {
      return false;
    } else if (type === "channels" && isEqual(channels, params.channel)) {
      return false;
    } else if (type === "tags" && isEqual(tags.join(","), params.tags)) {
      return false;
    } else if (type === "state" && isEqual(states, params.state)) {
      return false;
    } else if (type === "status" && isEqual(statuses, params.status)) {
      return false;
    } else if (
      type === "listingStatus" &&
      isEqual(listingStatus, params.listingStatus)
    ) {
      return false;
    } else if (type === "clear") {
      if (hasRole(user, ["client"])) {
        setBrands([]);
      } else {
        // set client to current client
        setClients(params.clients);
        setBrands([]);
      }
      setProducts([]);
      setPurchaseStatus([]);
      setCountries([]);
      setCategories([]);
      setTags([]);
      setMarketplaces([]);
      setInfringements([]);
      setChannels([]);
      setListingStatus([]);
      setStates([]);
      setStatuses([]);
      setSeller("");
      setAssigned([]);
      setTestPurchase(false);
      setFiled("all");
      setDateField("Created");
      setStartDate(dayjs().startOf("year").valueOf());
      setEndDate(dayjs().add(1, "day").endOf("day").valueOf());
      setValue("");
      setSortBy("created");
      setOrder("desc");
      dispatch(
        setParams({
          ...params,
          clients: [],
          brands: [],
          tags: [],
          products: [],
          assigned: [],
          countries: [],
          categories: [],
          marketplaces: [],
          infringementTypes: [],
          channel: [],
          listingStatus: [],
          purchaseStatus: [],
          state: [],
          status: [],
          seller: "",
          testPurchase: false,
          field: "created",
          value: "",
          dateField: "Created",
          start: dayjs().startOf("year").valueOf(),
          end: dayjs().add(1, "day").endOf("day").valueOf(),
          sortBy: "created",
          order: "desc",
          page: 0,
          filed: "all",
        })
      );
      return false;
    }

    dispatch(
      setParams({
        ...params,
        clients: clients,
        brands: brands,
        tags: tags,
        assigned: assigned,
        products: products,
        countries: countries,
        categories: categories,
        marketplaces: marketplaces,
        infringementTypes: infringements,
        channel: channels,
        purchaseStatus: purchaseStatus,
        listingStatus: listingStatus,
        state: states,
        status: statuses,
        seller: seller,
        testPurchase: testPurchase,
        field: value ? "fulltext" : undefined,
        value: value,
        dateField: dateField,
        start: startDate.valueOf(),
        end: endDate.valueOf(),
        sortBy: sortBy,
        order: order,
        page: 0,
        filed: filed,
      })
    );
  };

  const setSavedFilter = (filter) => {
    let mappedParams = {
      ...params,
      brands: [],
      clients: [],
      products: [],
      countries: [],
      categories: [],
      marketplaces: [],
      infringementTypes: [],
      assigned: [],
      channel: [],
      tags: [],
      listingStatus: [],
      purchaseStatus: [],
      state: [],
      status: [],
      seller: undefined,
      testPurchase: false,
      field: undefined,
      value: undefined,
      endDate: undefined,
      dateField: undefined,
      sortBy: undefined,
      order: undefined,
      start: undefined,
      end: undefined,
      filed: undefined,
    };
    if (filter["brand"]) {
      mappedParams.brands = filter["brand"].split("|");
      setBrands(mappedParams.brands);
    }
    if (filter["assigned"]) {
      mappedParams.assigned = filter["assigned"].split("|");
      setAssigned(mappedParams.assigned);
    }
    if (filter["client"]) {
      mappedParams.clients = filter["client"].split("|");
      setBrands(mappedParams.clients);
    }
    if (filter["products"]) {
      mappedParams.products = filter["products"].split("|");
      setProducts(mappedParams.products);
    }
    if (filter["purchaseStatus"]) {
      mappedParams.purchaseStatus = filter["purchaseStatus"].split("|");
      setPurchaseStatus(mappedParams.purchaseStatus);
    }
    if (filter["country"]) {
      mappedParams.countries = filter["country"].split("|");
      setCountries(mappedParams.countries);
    }
    if (filter["categories"]) {
      mappedParams.categories = filter["categories"].split("|");
      setCategories(mappedParams.categories);
    }
    if (filter["platform"]) {
      mappedParams.marketplaces = filter["platform"].split("|");
      setMarketplaces(mappedParams.marketplaces);
    }
    if (filter["seller"]) {
      mappedParams.seller = filter["seller"];
      setSeller(mappedParams.seller);
    }
    if (filter["filed"]) {
      mappedParams.filed = filter["filed"];
      setFiled(mappedParams.filed);
    }
    if (filter["toi"]) {
      mappedParams.infringementTypes = filter["toi"].split("|");
      setInfringements(mappedParams.infringementTypes);
    }
    if (filter["channel"]) {
      mappedParams.channel = filter["channel"].split("|");
      setChannels(mappedParams.channel);
    }
    if (filter["tags"]) {
      mappedParams.tags = filter["tags"].split("|");
      setTags(mappedParams.tags);
    }
    if (filter["listingStatus"]) {
      mappedParams.listingStatus = filter["listingStatus"].split("|");
      setListingStatus(mappedParams.listingStatus);
    }
    if (filter["state"]) {
      mappedParams.state = filter["state"].split("|");
      setStates(mappedParams.state);
    }
    if (filter["testPurchase"]) {
      mappedParams.testPurchase = filter["testPurchase"];
      setTestPurchase(mappedParams.testPurchase);
    }
    if (filter["dateField"]) {
      mappedParams.dateField = filter["dateField"];
      setDateField(mappedParams.dateField);
    }
    if (filter["start"]) {
      mappedParams.start = Number(filter["start"]);
      setStartDate(dayjs(mappedParams.start));
    }
    if (filter["end"]) {
      mappedParams.end = Number(filter["end"]);
      setEndDate(dayjs(mappedParams.end));
    }
    if (filter["value"]) {
      mappedParams.value = filter["value"];
      mappedParams.field = "fulltext";
      setValue(mappedParams.value);
    }
    if (filter["sortBy"]) {
      mappedParams.sortBy = filter["sortBy"];
      setSortBy(mappedParams.sortBy);
    }
    if (filter["order"]) {
      mappedParams.order = filter["order"];
      setOrder(mappedParams.order);
    }
    dispatch(setParams(mappedParams));
  };

  React.useEffect(() => {
    updateFilters("testPurchase");
    // eslint-disable-next-line
  }, [testPurchase]);

  React.useEffect(() => {
    updateFilters("filed");
    // eslint-disable-next-line
  }, [filed]);

  React.useEffect(() => {
    updateFilters("tags");
    // eslint-disable-next-line
  }, [tags]);

  React.useEffect(() => {
    updateFilters("dateField");
    // eslint-disable-next-line
  }, [dateField]);

  React.useEffect(() => {
    setStartDate(dayjs(params.start));
    // eslint-disable-next-line
  }, [params.start]);

  React.useEffect(() => {
    setEndDate(dayjs(params.end));
    // eslint-disable-next-line
  }, [params.end]);

  React.useEffect(() => {
    setCountries(params.countries);
    // eslint-disable-next-line
  }, [params.countries]);

  React.useEffect(() => {
    setCategories(categories);
    // eslint-disable-next-line
  }, [categories]);

  React.useEffect(() => {
    setPurchaseStatus(purchaseStatus);
    // eslint-disable-next-line
  }, [purchaseStatus]);

  React.useEffect(() => {
    let tempMarketplace = {};
    if (countries.length && all_marketplaces) {
      Object.entries(all_marketplaces).forEach((marketplace) => {
        if (countries.indexOf(marketplace[1]?.country) !== -1) {
          tempMarketplace[marketplace[0]] = marketplace[1];
        }
      });
      refineMarketplaceData(tempMarketplace);
    } else {
      refineMarketplaceData(all_marketplaces);
    }
    // eslint-disable-next-line
  }, [countries]);

  React.useEffect(() => {
    if (brandOpen === false) {
      updateFilters("brands");
    }
    // eslint-disable-next-line
  }, [brandOpen, brands]);

  React.useEffect(() => {
    if (assignedOpen === false) {
      updateFilters("assigned");
    }
    // eslint-disable-next-line
  }, [assignedOpen, assigned]);

  React.useEffect(() => {
    if (assignedOpen === false) {
      updateFilters("assigned");
    }
    // eslint-disable-next-line
  }, [assignedOpen, assigned]);

  const handleFilterDropdownChange = async (event, filterType) => {
    const {
      target: { value },
    } = event;

    switch (filterType) {
      case "countries":
        setCountries(typeof value === "string" ? value.split(",") : value);
        break;
      case "categories":
        setCategories(typeof value === "string" ? value.split(",") : value);
        break;
      case "purchaseStatus":
        setPurchaseStatus(typeof value === "string" ? value.split(",") : value);
        break;
      case "clients":
        setClients(typeof value === "string" ? value.split(",") : value);
        break;
      case "brands":
        if (value.includes("0all")) {
          if (value.length === allBrands.length + 1) {
            setBrands([]);
          } else {
            setBrands(allBrands);
          }
        } else {
          setBrands(typeof value === "string" ? value.split(",") : value);
        }
        if (!brandOpen) {
          updateFilters("brands");
        }
        break;
      case "assigned":
        if (value.includes("0all")) {
          if (value.length === users.length + 1) {
            setAssigned([]);
          } else {
            setAssigned(users.map((u) => u.value));
          }
        } else {
          setAssigned(typeof value === "string" ? value.split(",") : value);
        }
        if (!assignedOpen) {
          updateFilters("assigned");
        }
        break;
      case "products":
        let prods = Object.values(productData).flat(1);
        if (value.includes("all")) {
          if (value.length === prods.length + 1) {
            setProducts([]);
          } else {
            setProducts(prods);
          }
        } else {
          setProducts(typeof value === "string" ? value.split(",") : value);
        }
        break;
      case "marketplace":
        if (value.includes("all")) {
          if (value.length === marketPlaceOptions.length + 1) {
            setMarketplaces([]);
          } else {
            setMarketplaces(marketPlaceOptions.map((m) => m.value));
          }
        } else {
          setMarketplaces(typeof value === "string" ? value.split(",") : value);
        }
        break;
      case "infringements":
        setInfringements(typeof value === "string" ? value.split(",") : value);
        break;
      case "channels":
        setChannels(typeof value === "string" ? value.split(",") : value);
        break;
      case "listingStatus":
        setListingStatus(typeof value === "string" ? value.split(",") : value);
        break;
      case "state":
        setStates(typeof value === "string" ? value.split(",") : value);
        break;
      case "status":
        setStatuses(typeof value === "string" ? value.split(",") : value);
        break;
      default:
        return "";
    }
  };

  const useStyles = makeStyles({
    dateclass: {
      border: "1px solid #a5afc3",
    },
    expandmoreiconcolor: {
      color: "#a5afc3",
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "#a5afc3 !important",
    },

    customOutline: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#a5afc3",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#a5afc3",
      },
    },
  });
  const classes = useStyles();

  return (
    <>
      <Box sx={{ width: "100%", marginTop: "4px", marginBottom: "20px" }}>
        <Stack spacing={2} direction="row">
          <Grid container spacing={2}>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {(location === "/listings" || location === "/") && (
                <PopupState variant="popover" popupId="filter-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <StyledButton
                        variant="text"
                        {...bindTrigger(popupState)}
                        sx={{ mr: 14 }}
                      >
                        Saved Filters
                      </StyledButton>
                      <Menu {...bindMenu(popupState)}>
                        {user?.filters?.map((f, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setSavedFilter(f.params);
                              popupState.close();
                            }}
                          >
                            <ListItemText>{f.name}</ListItemText>
                            <Tooltip title="Delete this filter">
                              <ListItemIcon
                                sx={{ ml: 10 }}
                                onClick={(e) => {
                                  handleDeleteFilter(index);
                                  popupState.close();
                                  e.stopPropagation();
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </ListItemIcon>
                            </Tooltip>
                          </MenuItem>
                        ))}
                        <MenuItem
                          disabled={
                            brands.length === 0 &&
                            assigned.length === 0 &&
                            countries.length === 0 &&
                            categories.length === 0 &&
                            marketplaces.length === 0 &&
                            seller === "" &&
                            value === "" &&
                            sortBy === "" &&
                            order === "" &&
                            filed === "all" &&
                            infringements.length === 0 &&
                            states.length === 0 &&
                            testPurchase === false &&
                            tags.length === 0
                          }
                          onClick={() => {
                            popupState.close();
                            handleSaveFilter();
                          }}
                        >
                          Save current...
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              )}
              <StyledButton
                variant="text"
                onClick={() => updateFilters("clear")}
              >
                Clear Filter
              </StyledButton>
            </Grid>
            {location !== "/sellers" && location !== "/domains" && (
              <>
                <Grid item sx={{ ml: 0, width: 210, pl: 0, pt: 0 }}>
                  <FormControl
                    classes={{ root: classes.customOutline }}
                    sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                    variant="outlined"
                  >
                    <CustomDatePicker
                      date={startDate}
                      setDate={handleDateChange}
                      dateField={dateField}
                      setDateField={setDateField}
                      fields={
                        location === "/complaints"
                          ? ["Created", "Filing", "Takedown"]
                          : location === "/investigations"
                          ? ["Assigned", "Closed", "Created", "Due"]
                          : ["Created", "Filing", "Reviewed", "Takedown"]
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item sx={{ ml: 0, width: 210, pl: 0, pt: 0 }}>
                  <FormControl
                    classes={{ root: classes.customOutline }}
                    sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                    variant="outlined"
                  >
                    <CustomDatePicker
                      date={endDate}
                      minDate={startDate}
                      setDate={handleDateChange}
                      dateField={dateField}
                      setDateField={setDateField}
                      label="End Date"
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {location !== "/domains" && (
              <div style={{ marginLeft: 8 }}>
                {hasRole(user, ["client"]) === false &&
                  allClients &&
                  user?.brands?.length > 1 &&
                  clients &&
                  location !== "/investigations" && (
                    <Grid item>
                      <FormControl
                        sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                        variant="outlined"
                        classes={{ root: classes.customOutline }}
                      >
                        <InputLabel id="client-multiple-checkbox-label">
                          Client
                        </InputLabel>
                        <Select
                          labelId="client-multiple-checkbox-label"
                          id="client-multiple-checkbox"
                          multiple
                          value={clients}
                          onChange={(e) =>
                            handleFilterDropdownChange(e, "clients")
                          }
                          onClose={() => updateFilters("clients")}
                          input={<OutlinedInput label=" client " />}
                          renderValue={(selected) => {
                            return selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                        >
                          {user &&
                            clients &&
                            allClients?.map((name, index) => (
                              <MenuItem
                                key={index}
                                value={name}
                                style={getStyles(name, clients, theme)}
                              >
                                <Checkbox
                                  checked={clients.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                {users && location === "/investigations" && (
                  <div>
                    <Grid item>
                      <FormControl
                        sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                        variant="outlined"
                        classes={{ root: classes.customOutline }}
                      >
                        <InputLabel id="purchase-status-multiple-checkbox-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="purchase-status-multiple-checkbox-label"
                          id="purchase-status-multiple-chip"
                          multiple
                          value={purchaseStatus}
                          onChange={(e) =>
                            handleFilterDropdownChange(e, "purchaseStatus")
                          }
                          onClose={() => updateFilters("purchaseStatus")}
                          style={{
                            placeholder: {
                              color: "purple",
                              fontSize: 12,
                              fontWeight: "bold",
                            },
                          }}
                          input={<OutlinedInput label=" Status " />}
                          renderValue={(selected) => {
                            return TESTPURCHASE_STATUS.filter((o) =>
                              selected.includes(o.value)
                            )
                              .map((o) => o.text)
                              .join(",");
                          }}
                          MenuProps={MenuProps}
                        >
                          {TESTPURCHASE_STATUS.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              <Checkbox
                                checked={
                                  purchaseStatus.indexOf(option.value) > -1
                                }
                              />
                              <ListItemText primary={option.text} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl
                        sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                        variant="outlined"
                        classes={{ root: classes.customOutline }}
                      >
                        <Autocomplete
                          multiple
                          limitTags={2}
                          value={assigned}
                          filterOptions={filterOptions}
                          onChange={(_event, newValue) => {
                            handleFilterDropdownChange(
                              {
                                target: {
                                  value:
                                    newValue.length === 0
                                      ? []
                                      : newValue.join(","),
                                },
                              },
                              "assigned"
                            );
                          }}
                          onClose={() => setAssignedOpen(false)}
                          onOpen={() => setAssignedOpen(true)}
                          id="assigned-select"
                          options={
                            ["0all", ...getUserOptions().map((o) => o.label)] ||
                            []
                          }
                          groupBy={(option) => option.group}
                          disableCloseOnSelect
                          renderOption={(props, option, { selected }) => (
                            <div key={option}>
                              <MenuItem
                                {...props}
                                style={getStyles(option, assigned, theme)}
                              >
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={
                                    option === "0all"
                                      ? assigned.length > 0 &&
                                        assigned.length === users.length
                                      : selected
                                  }
                                  indeterminate={
                                    option === "0all"
                                      ? assigned.length > 0 &&
                                        assigned.length < users.length
                                      : false
                                  }
                                />
                                {option === "0all" ? "Select All" : option}
                              </MenuItem>
                              {option === "0all" && <Divider />}
                            </div>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Assigned To" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </div>
                )}
                {user?.brands?.length > 1 && (
                  <Grid item>
                    <FormControl
                      sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                      variant="outlined"
                      classes={{ root: classes.customOutline }}
                    >
                      <Autocomplete
                        multiple
                        limitTags={2}
                        value={brands}
                        filterOptions={filterOptions}
                        onChange={(_event, newValue) => {
                          handleFilterDropdownChange(
                            {
                              target: {
                                value:
                                  newValue.length === 0
                                    ? []
                                    : newValue.join(","),
                              },
                            },
                            "brands"
                          );
                        }}
                        onClose={() => setBrandOpen(false)}
                        onOpen={() => setBrandOpen(true)}
                        id="brands-select"
                        options={allBrands ? ["0all", ...allBrands] : []}
                        disableCloseOnSelect
                        renderOption={(props, option, { selected }) => (
                          <div key={option}>
                            <MenuItem
                              {...props}
                              style={getStyles(option, brands, theme)}
                            >
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={
                                  option === "0all"
                                    ? brands.length > 0 &&
                                      brands.length === allBrands.length
                                    : selected
                                }
                                indeterminate={
                                  option === "0all"
                                    ? brands.length > 0 &&
                                      brands.length < allBrands.length
                                    : false
                                }
                              />
                              {option === "0all" ? "Select All" : option}
                            </MenuItem>
                            {option === "0all" && <Divider />}
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Brand" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
                {productData &&
                location !== "/investigations" &&
                Object.values(productData).flat(1).length > 0 ? (
                  <Grid item>
                    <FormControl
                      sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                      variant="outlined"
                      classes={{ root: classes.customOutline }}
                    >
                      <InputLabel id="product-multiple-checkbox-label">
                        Product
                      </InputLabel>
                      <Select
                        labelId="product-multiple-checkbox-label"
                        id="product-multiple-checkbox"
                        multiple
                        value={products}
                        onChange={(e) =>
                          handleFilterDropdownChange(e, "products")
                        }
                        onClose={() => updateFilters("products")}
                        input={<OutlinedInput label=" Product " />}
                        renderValue={(selected) => {
                          return Object.values(productData)
                            .flat(1)
                            .filter((o) => selected.includes(o))
                            .map((o) => o)
                            .join(", ");
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="all">
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                products.length > 0 &&
                                products.length ===
                                  Object.values(productData).flat(1).length
                              }
                              indeterminate={
                                products.length > 0 &&
                                products.length <
                                  Object.values(productData).flat(1).length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {Object.keys(productData).map((brand) => {
                          let options = [];
                          options.push(
                            <ListSubheader key={brand}>{brand}</ListSubheader>
                          );
                          productData[brand].map((option, index) =>
                            options.push(
                              <MenuItem key={index} value={option}>
                                <Checkbox
                                  checked={products.indexOf(option) > -1}
                                />
                                <ListItemText primary={option} />
                              </MenuItem>
                            )
                          );
                          return options;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}

                <Grid item>
                  <FormControl
                    sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                    variant="outlined"
                    classes={{ root: classes.customOutline }}
                  >
                    <InputLabel id="marketplace-multiple-checkbox-label">
                      Platform
                    </InputLabel>
                    <Select
                      labelId="marketplace-multiple-checkbox-label"
                      id="marketplace-multiple-checkbox"
                      multiple
                      value={marketplaces}
                      onChange={(e) =>
                        handleFilterDropdownChange(e, "marketplace")
                      }
                      onClose={() => updateFilters("marketplaces")}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label=" Marketplace "
                        />
                      }
                      renderValue={(selected) => {
                        return marketPlaceOptions
                          .filter((o) => selected.includes(o.value))
                          .map((o) => o.text)
                          .join(",");
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="all">
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              marketplaces.length > 0 &&
                              marketplaces.length === marketPlaceOptions.length
                            }
                            indeterminate={
                              marketplaces.length > 0 &&
                              marketplaces.length < marketPlaceOptions.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      <ListSubheader>Marketplaces</ListSubheader>
                      {marketPlaceOptions
                        .filter((o) => o.channel === "MARKETPLACE")
                        .map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  marketplaces.indexOf(option.value) > -1
                                }
                              />
                            </ListItemIcon>
                            <ListItemText primary={option.text} />
                          </MenuItem>
                        ))}
                      <ListSubheader>Social Media</ListSubheader>
                      {marketPlaceOptions
                        .filter((o) => o.channel === "SOCIAL_MEDIA")
                        .map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  marketplaces.indexOf(option.value) > -1
                                }
                              />
                            </ListItemIcon>
                            <ListItemText primary={option.text} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </div>
            )}
            {location !== "/" &&
              location !== "/domains" &&
              location !== "/investigations" && (
                <React.Fragment>
                  <Grid item>
                    <TextField
                      classes={{ root: classes.customOutline }}
                      sx={{ ml: 1, mt: -1, mb: -1, width: 200 }}
                      id="outlined-seller"
                      value={seller}
                      label="Seller"
                      onChange={(e) => setSeller(e.target.value)}
                      variant="outlined"
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          updateFilters("seller");
                          ev.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                </React.Fragment>
              )}
            {location !== "/sellers" &&
              location !== "/investigations" &&
              location !== "/" &&
              location !== "/domains" && (
                <Grid item>
                  <FormControl
                    sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                    variant="outlined"
                    classes={{ root: classes.customOutline }}
                  >
                    <InputLabel id="infringement-multiple-checkbox-label">
                      Infringement
                    </InputLabel>
                    <Select
                      labelId="infringement-multiple-checkbox-label"
                      id="infringement-multiple-checkbox"
                      multiple
                      value={infringements}
                      onChange={(e) =>
                        handleFilterDropdownChange(e, "infringements")
                      }
                      onClose={() => updateFilters("infringements")}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label=" Infringement "
                        />
                      }
                      renderValue={(selected) => {
                        return INFRINGEMENT_TYPES.filter((o) =>
                          selected.includes(o.value)
                        )
                          .map((o) => o.text)
                          .join(",");
                      }}
                      MenuProps={MenuProps}
                    >
                      {INFRINGEMENT_TYPES.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          <Checkbox
                            checked={infringements.indexOf(option.value) > -1}
                          />
                          <ListItemText primary={option.text} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            {location === "/listings" && (
              <Grid item>
                <FormControl
                  sx={{ ml: 1, mt: -1, width: 200, pl: 0, pt: 0 }}
                  variant="outlined"
                  classes={{ root: classes.customOutline }}
                >
                  <InputLabel id="state-multiple-checkbox-label">
                    State
                  </InputLabel>
                  <Select
                    labelId="state-multiple-chip-label"
                    id="state-multiple-chip"
                    multiple
                    value={states}
                    onChange={(e) => handleFilterDropdownChange(e, "state")}
                    onClose={() => updateFilters("state")}
                    style={{
                      placeholder: {
                        color: "purple",
                        fontSize: 12,
                        fontWeight: "bold",
                      },
                    }}
                    input={<OutlinedInput label=" State " />}
                    renderValue={(selected) => {
                      return STATE_TYPES.filter((o) =>
                        selected.includes(o.value)
                      )
                        .map((o) => o.text)
                        .join(",");
                    }}
                    MenuProps={MenuProps}
                  >
                    {STATE_TYPES.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        <Checkbox checked={states.indexOf(option.value) > -1} />
                        <ListItemText primary={option.text} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {location === "/complaints" &&
              location !== "/domains" &&
              location !== "/investigations" && (
                <Grid item>
                  <FormControl
                    sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                    variant="outlined"
                    classes={{ root: classes.customOutline }}
                  >
                    <InputLabel id="status-multiple-checkbox-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="status-multiple-chip-label"
                      id="status-multiple-chip"
                      multiple
                      value={statuses}
                      onChange={(e) => handleFilterDropdownChange(e, "status")}
                      onClose={() => updateFilters("status")}
                      style={{
                        placeholder: {
                          color: "purple",
                          fontSize: 12,
                          fontWeight: "bold",
                        },
                      }}
                      input={<OutlinedInput label=" Status " />}
                      renderValue={(selected) => {
                        return COMPLAINT_STATUS_TYPES.filter((o) =>
                          selected.includes(o.value)
                        )
                          .map((o) => o.text)
                          .join(",");
                      }}
                      MenuProps={MenuProps}
                    >
                      {COMPLAINT_STATUS_TYPES.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          <Checkbox
                            checked={statuses.indexOf(option.value) > -1}
                          />
                          <ListItemText primary={option.text} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
          </Grid>
        </Stack>

        {saveFilter && (
          <SaveFilterDialog
            params={params}
            user={user}
            handleClose={() => setSaveFilter(false)}
          />
        )}
      </Box>
      {location !== "/domains" && (
        <List
          component="div"
          disablePadding
          sx={{ marginLeft: "-16px", marginRight: "-16px" }}
        >
          <ListItemButton
            sx={{ padding: "8px 16px" }}
            onClick={handleClickAdvancedFilter}
          >
            <ListItemIcon>
              <TuneIcon />
            </ListItemIcon>
            <ListItemText primary="Advanced Filter" />
            {openAdvancedFilter ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            sx={{ padding: "0px 16px" }}
            in={openAdvancedFilter}
            timeout="auto"
            unmountOnExit={false}
          >
            <Box sx={{ width: "100%", marginTop: "4px", marginBottom: "20px" }}>
              <Stack spacing={2} direction="row">
                <Grid container spacing={2}>
                  <Grid item>
                    <CountrySelect
                      countries={countries}
                      countryOptions={countryOptions}
                      classes={classes}
                      handleFilterDropdownChange={handleFilterDropdownChange}
                      updateFilters={updateFilters}
                    />
                  </Grid>
                  {(location === "/listings" || location === "/") && (
                    <Grid item>
                      <FormControl
                        sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                        variant="outlined"
                        classes={{ root: classes.customOutline }}
                      >
                        <InputLabel id="channel-multiple-checkbox-label">
                          Channel
                        </InputLabel>
                        <Select
                          labelId="channel-multiple-chip-label"
                          id="channel-multiple-chip"
                          multiple
                          value={channels}
                          onChange={(e) =>
                            handleFilterDropdownChange(e, "channels")
                          }
                          onClose={() => updateFilters("channels")}
                          style={{
                            placeholder: {
                              color: "purple",
                              fontSize: 12,
                              fontWeight: "bold",
                            },
                          }}
                          input={<OutlinedInput label=" Channel " />}
                          renderValue={(selected) => {
                            return PLATFORM_TYPE.filter((o) =>
                              selected.includes(o.value)
                            )
                              .map((o) => o.text)
                              .join(",");
                          }}
                          MenuProps={MenuProps}
                        >
                          {PLATFORM_TYPE.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              <Checkbox
                                checked={channels.indexOf(option.value) > -1}
                              />
                              <ListItemText primary={option.text} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {location === "/listings" && (
                    <Grid item>
                      <FormControl
                        sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                        variant="outlined"
                        classes={{ root: classes.customOutline }}
                      >
                        <Autocomplete
                          multiple
                          id="tags"
                          options={all_tags || []}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Tags"
                            />
                          )}
                          value={tags}
                          onChange={(_e, newInputValue) => {
                            setTags(newInputValue);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {location !== "/" && (
                    <Grid item>
                      <TextField
                        classes={{ root: classes.customOutline }}
                        sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                        id="outlined-search"
                        value={value}
                        label="Search"
                        onChange={(e) => setValue(e.target.value)}
                        variant="outlined"
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            updateFilters("search");
                            ev.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {location === "/listings" && (
                    <>
                      <Grid item>
                        <FormControl
                          sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                          variant="outlined"
                          classes={{ root: classes.customOutline }}
                        >
                          <InputLabel id="listing-status-multiple-checkbox-label">
                            Listing Status
                          </InputLabel>
                          <Select
                            labelId="listing-status-multiple-checkbox-label"
                            id="listing-status-multiple-chip"
                            multiple
                            value={listingStatus}
                            onChange={(e) =>
                              handleFilterDropdownChange(e, "listingStatus")
                            }
                            onClose={() => updateFilters("listingStatus")}
                            style={{
                              placeholder: {
                                color: "purple",
                                fontSize: 12,
                                fontWeight: "bold",
                              },
                            }}
                            input={<OutlinedInput label=" Listing Status " />}
                            renderValue={(selected) => {
                              return LISTING_STATUS.filter((o) =>
                                selected.includes(o.value)
                              )
                                .map((o) => o.text)
                                .join(",");
                            }}
                            MenuProps={MenuProps}
                          >
                            {LISTING_STATUS.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                <Checkbox
                                  checked={
                                    listingStatus.indexOf(option.value) > -1
                                  }
                                />
                                <ListItemText primary={option.text} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {all_categories && (
                        <Grid item>
                          <FormControl
                            sx={{ m: 1, width: 200, pl: 0, pt: 0 }}
                            variant="outlined"
                            classes={{ root: classes.customOutline }}
                          >
                            <InputLabel id="product-categories-multiple-checkbox-label">
                              Product Category
                            </InputLabel>
                            <Select
                              labelId="product-categories-multiple-checkbox-label"
                              id="product-categories-multiple-checkbox"
                              multiple
                              value={categories}
                              onChange={(e) =>
                                handleFilterDropdownChange(e, "categories")
                              }
                              onClose={() => updateFilters("categories")}
                              input={
                                <OutlinedInput label=" Product Category " />
                              }
                              renderValue={(selected) => {
                                return Object.values(categories)
                                  .flat(1)
                                  .filter((o) => selected.includes(o))
                                  .map((o) => o)
                                  .join(", ");
                              }}
                              MenuProps={MenuProps}
                            >
                              {all_categories.map((cat) => {
                                let options = [];
                                options.push(
                                  <MenuItem key={cat} value={cat}>
                                    <Checkbox
                                      checked={categories.indexOf(cat) > -1}
                                    />
                                    <ListItemText primary={cat} />
                                  </MenuItem>
                                );
                                return options;
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item>
                        <FormGroup sx={{ ml: 8 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="testPurchase"
                                checked={testPurchase}
                                onChange={(e) => {
                                  setTestPurchase(e.target.checked);
                                }}
                              />
                            }
                            label="Investigations"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item>
                        <ToggleButtonGroup
                          sx={{ ml: 13 }}
                          size="small"
                          value={filed}
                          exclusive
                          onChange={handleFiled}
                          aria-label="filed status"
                        >
                          <ToggleButton value={"all"} aria-label="all">
                            <Tooltip title="All listings">
                              <span>
                                <RuleFolderIcon />
                              </span>
                            </Tooltip>
                          </ToggleButton>

                          <ToggleButton value={"false"} aria-label="not filed">
                            <Tooltip title="Not filed listings">
                              <span>
                                <FolderOpenIcon />
                              </span>
                            </Tooltip>
                          </ToggleButton>

                          <ToggleButton value={"true"} aria-label="filed">
                            <Tooltip title="Filed listings">
                              <span>
                                <FolderIcon />
                              </span>
                            </Tooltip>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
            </Box>
          </Collapse>
        </List>
      )}
    </>
  );
};

export default Filter;
