import { createContext, useEffect, useReducer } from "react";
import remote from "../utils/axios";
import axios from "axios";
import { isValidToken, setSession } from "../hooks/utils/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_OUT = "SIGN_OUT";

const base = process.env.REACT_APP_API_TARGET
  ? `${window.location.protocol}//${window.location.hostname}:8080`
  : `${window.location.protocol}//${window.location.host}`;

const initialState = {
  isAuthenticated: true,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const localAxios = axios.create({
          baseURL: base,
        });
        const localUser = await localAxios.get("/api/v1/users/current");
        let splits = localUser.data.split(":");

        const response = await remote.get(
          `/api/v1/store/users/login/${splits[1]}`,
          { headers: { "x-authorization": splits[0], "x-nonce": splits[2] } }
        );
        const user = response.data;

        if (user && isValidToken(user.auth)) {
          window.localStorage.setItem("accessToken", user.auth);
          setSession(user.auth);
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        window.open(`/login`, "_self");
      }
    };
    initialize();
  }, []);

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
    window.localStorage.removeItem("accessToken");
  };

  const setUser = (user) => {
    dispatch({
      type: INITIALIZE,
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signOut,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
